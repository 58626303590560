<template>
    <MainFunctions :dialog="dialog" :buttons="permitButtons" :title="$t('menu.nav-title')"></MainFunctions>
</template>

<script>
import store, { LOCAL_HOME_KEY } from '@/store'
import MainFunctions from "@/components/MainFunctions"
import { HOMETYPES } from '@/utils/utils'

export default {
    name: 'Menu',
    components: { MainFunctions },
    data() {
        return {
            buttons: [
                { title: 'menu.buttons.put-in', color: "success", icon: 'move_to_inbox', routeName: 'putin'},
                { title: 'menu.buttons.take-out', color: "deep-orange darken-2", icon: 'unarchive', routeName: 'takeout'},
                { title: 'menu.buttons.transaction-records', color: "blue-grey darken-2", icon: 'list_alt', routeName: 'payments'},
                { title: 'menu.buttons.take-out-fix', color: "brown darken-2", icon: 'dialpad', routeName: 'takeout-change'},
                { title: 'menu.buttons.settings', color: "purple darken-2", icon: 'person', routeName: 'setting'},
                { title: 'menu.buttons.pending-list', color: "grey darken-2", icon: 'pending_actions', routeName: 'pending-record'}
            ],
            dialog: false,
        }
    },
    computed: {
        permitButtons() {
            if (this.$store.getters.pageHome === HOMETYPES.STORE ) {
                localStorage.setItem(LOCAL_HOME_KEY, HOMETYPES.STORE)
                const permits=["payments","setting"];
                return this.buttons.filter(a=>permits.includes(a.routeName));
            } else if (this.$store.getters.pageHome === HOMETYPES.WASH ) {
                localStorage.setItem(LOCAL_HOME_KEY, HOMETYPES.WASH)
                const permits=["payments","takeout-change","setting"];
                return this.buttons.filter(a=>permits.includes(a.routeName));
            } else {
                localStorage.setItem(LOCAL_HOME_KEY, HOMETYPES.LOCKER)
            }
            
            return this.buttons;
        },
    },
    watch: {
        userSynced() {
            if (this.userSynced && this.query) {
                this.dialog = false
                this.shortcut()
            }
        }
    },
    activated() {
        //重新載入頁面一次
        if (localStorage.getItem('pagehome')) {
            store.commit('setPageHome', parseInt(localStorage.getItem('pagehome')))
        }
    }
}
</script>
